/* eslint-disable no-underscore-dangle */
import { Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { WindowUtils } from '../utils/window.util';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { Tidio } from '../models/tidio.model';

const TIDIO_ELEMENT_ID = 'popsy-tidio';
const TIDIO_PUBLIC_KEY = '57w3xlaznmyabnx1nhygsqbb0puingdv';
const TIDIO_SDK_URL = `https://code.tidio.co/${TIDIO_PUBLIC_KEY}.js`;

@Injectable({
  providedIn: 'root',
})
export class TidioService {
  private readonly document: Document | null;
  private installingTidioSubject: Subject<Tidio | null> | null;

  constructor(
    private readonly injector: Injector,
    private readonly translateService: TranslocoService,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.installingTidioSubject = null;

    if (this.akitaRouterQuery.isBrowser) {
      try {
        this.document = this.injector.get<Document | null>(DOCUMENT, null);
      } catch (err) {
        this.document = null;
      }
    } else {
      this.document = null;
    }
  }

  private get tidioInstance(): Tidio | null {
    const windowRef: any | null = WindowUtils.window;
    if (windowRef && windowRef.tidioChatApi) {
      return windowRef.tidioChatApi as Tidio;
    }
    return null;
  }

  public setVisitorData(
    id?: string | null,
    name?: string | null,
    phone?: string | null,
    email?: string | null,
    city?: string | null,
    country?: string | null,
    tags?: Array<string> | null
  ): void {
    if (this.akitaRouterQuery.isBrowser) {
      const userInfo = {
        ...((window as any).tidioIdentify || {}),
      };

      if (id) {
        userInfo.distinct_id = id;
      }

      if (name) {
        userInfo.name = name;
      }

      if (email) {
        userInfo.email = email;
      }

      if (phone) {
        userInfo.phone = phone;
      }

      if (city) {
        userInfo.city = city;
      }

      if (country) {
        userInfo.country = `${country}`.toUpperCase();
      }

      if (tags) {
        const newTags = [...(userInfo.tags || [])];
        for (const tag of tags) {
          if (!newTags.includes(tag)) {
            newTags.push(tag);
          }
        }
        userInfo.tags = newTags;
      }

      try {
        (window as any).tidioIdentify = userInfo;

        if ((window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.setVisitorData(userInfo);
        }
      } catch (err) {}
    }
  }

  public addVisitorTags(tags?: Array<string> | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if (tags && (window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.addVisitorTags(tags);
        }
      } catch (err) {}
    }
  }

  public uninstallTidio(): void {
    if (this.document) {
      if (this.tidioInstance) {
        const windowRef: any | null = WindowUtils.window;
        if (windowRef && windowRef.tidioChatApi) {
          windowRef.tidioChatApi = null;
        }
      }

      const elementRef = this.document.getElementById(TIDIO_ELEMENT_ID);
      if (elementRef) {
        elementRef.remove();
      }
    }
  }

  public open(): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if ((window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.open();
        }
      } catch (err) {}
    }
  }

  public close(): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if ((window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.close();
        }
      } catch (err) {}
    }
  }

  public display(shouldDisplay?: boolean | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if ((window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.display(shouldDisplay);
        }
      } catch (err) {}
    }
  }

  public track(name?: string | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if (name && (window as any)?.tidioChatApi?.setVisitorData) {
          (window as any).tidioChatApi.track(name);
        }
      } catch (err) {}
    }
  }

  public installTidio(): Observable<Tidio | null> {
    const subscription = new Subscription();
    return new Observable((observer: Observer<Tidio | null>) => {
      if (this.document) {
        if (this.installingTidioSubject && !this.installingTidioSubject.closed) {
          subscription.add(
            this.installingTidioSubject.asObservable().subscribe({
              next: (instance: Tidio | null) => {
                observer.next(instance);
                observer.complete();

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
              error: (error: unknown) => {
                observer.error(error);
                observer.complete();

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
            })
          );
        } else {
          if (this.tidioInstance) {
            observer.next(this.tidioInstance);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }

          const elementRef = this.document.getElementById(TIDIO_ELEMENT_ID);
          if (elementRef) {
            elementRef.remove();
          }

          const headElement = this.document.getElementsByTagName('head');
          let parentElement = null;
          if (headElement.length > 0) {
            parentElement = headElement[0];
          } else {
            parentElement = this.document.getElementsByTagName('body')[0];
          }

          const script = this.document.createElement('script');
          script.setAttribute('id', TIDIO_ELEMENT_ID);
          script.setAttribute('nonce', 'a440a9a28f867f71651a8ee28d13d3f7');
          script.setAttribute('type', 'text/javascript');
          script.setAttribute('charset', 'utf-8');
          script.setAttribute('async', 'true');
          script.setAttribute('src', TIDIO_SDK_URL);

          script.addEventListener('load', () => {
            const instance = this.tidioInstance;
            if (this.installingTidioSubject && !this.installingTidioSubject.closed) {
              this.installingTidioSubject.next(instance);
              this.installingTidioSubject.complete();
              this.installingTidioSubject = null;
            }

            observer.next(instance);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          });

          script.addEventListener('error', (error: ErrorEvent) => {
            if (this.installingTidioSubject && !this.installingTidioSubject.closed) {
              this.installingTidioSubject.error(error);
              this.installingTidioSubject = null;
            }
            observer.error(error);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          });

          // Initialize
          const windowRef: any | null = WindowUtils.window;
          if (windowRef) {
            (window as any).tidioChatLang = this.translateService.getActiveLang();
            (this.document as any).tidioChatLang = this.translateService.getActiveLang();

            // Start Loading
            this.installingTidioSubject = new Subject();
            parentElement?.appendChild(script);
          } else {
            observer.next(null);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }
        }
      } else {
        observer.next(null);
        observer.complete();

        if (subscription) {
          subscription.unsubscribe();
        }
      }
    });
  }
}
